// import { createLogic } from "redux-logic";
import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";


const apiNamespace = "Promoters";

const getAllPromotersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PROMOTERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getAllCitiesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CITIES,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getAllSupervisorsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_SUPERVISORS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getPromoterLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PROMOTER,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getPermissionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PERMISSIONS,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const addPromoterLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_PROMOTER,
  showErrorMessage: true,
  successMessage: "Promoter created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/promoters/list"));
  },
});

const deletePromoterLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PROMOTER,
  showErrorMessage: true,
  successMessage: "Promoter Deleted successfully",
  showSuccessMessage: true,
});
const editPromoterLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_PROMOTER,
  showErrorMessage: true,
  showSuccessMessage: "Promoter Edited Successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/promoters/list"));
  },
});
const getAllPromotersAssignmentsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PROMOTERS_ASSIGNMENTS,
  showErrorMessage: true,
  showSuccessMessage: false,
});

export default [
  getAllPromotersLogic,
  addPromoterLogic,
  deletePromoterLogic,
  editPromoterLogic,
  getPermissionsLogic,
  getPromoterLogic,
  getAllCitiesLogic,
  getAllSupervisorsLogic,
  getAllPromotersAssignmentsLogic,
];
