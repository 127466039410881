// import { createReducer } from "utils/reduxsauce";
import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState(
    "promoters",
    {
      metadata: undefined,
      selectedPromoter: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    [],
    "promotersAssignments",
    {
      metadata: undefined,
      selectedPromoter: undefined,
      openDialog: false,
      openDeleteDialog: false,
    },
    [],
  ),
};

const promoterCreatedSuccess = (state, draft, payload) => {
  const promoters = state.promoters || [];
  draft.promoters = [...promoters, payload];

  draft.openDialog = false;
};
const promoterDeleteSuccess = (state, draft, payload) => {
  const promoters = state.promoters || [];
  draft.promoters = promoters.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};


const promoterEditedSuccess = (state, draft, payload) => {
  const promoters = state.promoters || [];
  var promoterIndex = promoters.findIndex((u) => u.id === payload.id);
  draft.promoters[promoterIndex] = payload;
  draft.openDialog = false;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.promoter_errors = [];
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "deletePromoter",
    stateName: "promoter",
    async: true,
    isPaginated: false,
    successCb: promoterDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "getAllPromoters",
    stateName: "promoters",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllCities",
    stateName: "cities",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllSupervisors",
    stateName: "supervisors",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPromoter",
    stateName: "promoter",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addPromoter",
    stateName: "promoter",
    async: true,
    isPaginated: false,
    successCb: promoterCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editPromoter",
    stateName: "promoter",
    async: true,
    isPaginated: false,
    successCb: promoterEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getPermissions",
    stateName: "permissions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: true,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllPromotersAssignments",
    stateName: "promotersAssignments",
    async: true,
    isPaginated: true,
  }),
});
