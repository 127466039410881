import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("outlets", { metadata: undefined }, []),
};

const outletDeleteSuccess = (state, draft, payload) => {
  debugger;
  const outlets = state.outlets || [];
  draft.outlets = outlets.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.outlet = undefined;
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllOutlets",
    stateName: "outlets",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getOutletWithId",
    stateName: "outlet",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "addOutlet",
    stateName: "outlet",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "uploadOutlets",
    stateName: "outlets",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "editOutlet",
    stateName: "outlet",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteOutlet",
    stateName: "outlet",
    async: true,
    isPaginated: false,
    successCb: outletDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "uploadAssignments",
    stateName: "outlets",
    async: true,
    isPaginated: false,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
