import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("reportsResponses", { metadata: undefined }, []),
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "addCustomReport",
    stateName: "reportsResponses",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCustomReport",
    stateName: "customReports",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCustomExcelReport",
    stateName: "customExcelReports",
    async: true,
    isPaginated: true,
  }),
});
