import { bindReducers, defaultState } from "../../utils/genState";

import { ActionTypes } from "./actions";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("spins", { metadata: undefined }, []),
  ...defaultState("rewards", { metadata: undefined }, []),
};

const removeSupervisorFromSpinSuccess = (state, draft, payload) => {
  const supervisors = state.assignedSupervisors || [];
  const notAssignedSupervisors = state.notAssignedSupervisors || [];
  draft.assignedSupervisors = supervisors.filter((a) => a.id != payload.id);
  draft.notAssignedSupervisors = [...notAssignedSupervisors,payload]
  draft.openDeleteDialog = false;
};

const assignSupervisorSuccess = (state, draft, payload) => {
  //debugger
  const supervisors = state.assignedSupervisors || [];
  draft.assignedSupervisors = [...supervisors, payload];
};

const spinDeleteSuccess = (state, draft, payload) => {
  const spins = state.spins || [];
  draft.spins = spins.filter((a) => a.id != payload?.id);
  draft.openDeleteDialog = false;
};

// const spinCreatedSuccess = (state, draft, payload) => {
//   const spins = state.spins || [];
//   draft.spins = [...spins, payload];
//   draft.openDialog = false;
// };

const setData = (state, draft, payload) => {
  const { item, value } = payload;
  draft[item] = value;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};
// Rewards
const rewardEditedSuccess = (state, draft, payload) => {
  const rewards = state.rewards || [];
  let rewardIndex = rewards.findIndex((u) => u.id === payload.id);
  draft.rewards[rewardIndex] = payload;
  draft.openDialog = false;
};

const rewardCreatedSuccess = (state, draft, payload) => {
  const rewards = state.rewards || [];
  draft.rewards = [...rewards, payload];
};

const rewardDeleteSuccess = (state, draft, payload) => {
  const rewards = state.rewards || [];
  draft.rewards = rewards.filter((a) => a.id != payload);
  draft.openDeleteDialog = false;
};
const setReward = (state, draft, payload) => {
  draft.reward = undefined;
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllSpin",
    stateName: "spins",
    async: true,
    isPaginated: true,
  }),

  ...bindReducers(ActionTypes, {
    action: "getSpinWithId",
    stateName: "spin",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createSpin",
    stateName: "spin",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateSpin",
    stateName: "spin",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteSpin",
    stateName: "spin",
    async: true,
    isPaginated: false,
    successCb: spinDeleteSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "setData",
    stateName: "data",
    async: false,
    isPaginated: false,
    successCb: setData,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: true,
    successCb: resetDialog,
  }),
  ///rewards
  ...bindReducers(ActionTypes, {
    action: "getAllRewards",
    stateName: "rewards",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRewardWithId",
    stateName: "reward",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createReward",
    stateName: "reward",
    async: true,
    isPaginated: false,
    successCb: rewardCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateReward",
    stateName: "reward",
    async: true,
    isPaginated: false,
    successCb: rewardEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteReward",
    stateName: "reward",
    async: true,
    isPaginated: false,
    successCb: rewardDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "setReward",
    stateName: "reward",
    async: false,
    isPaginated: false,
    successCb: setReward,
  }),
  // Supervisor
  ...bindReducers(ActionTypes, {
    action: "assignedSupervisor",
    stateName: "assignedSupervisors",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "notAssignedSupervisor",
    stateName: "notAssignedSupervisors",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "removeSupervisorFromSpin",
    stateName: "assignedSupervisor",
    async: true,
    isPaginated: false,
    successCb: removeSupervisorFromSpinSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "assignSupervisor",
    stateName: "assignedSupervisor",
    async: true,
    isPaginated: false,
    successCb: assignSupervisorSuccess,
  }),
});
