export default function (/**@type {ApisauceInstance} */ api) {
  const addCustomReport = (payload) =>
    api.post(`/ReportQuestion/add-custom-report`, payload);
  const getCustomReport = (payload) =>
    api.post(`/ReportQuestion/Get-custom-report`, payload);
    const getCustomExcelReport = (payload) =>
      api.post(`/ReportQuestion/Get-custom-excel-report`, payload);

  return {
    reportsResponses: {
      addCustomReport,
      getCustomReport,
      getCustomExcelReport,
    },
  };
}
;