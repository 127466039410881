export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllPromoters = (paging) => api.get(`Promoter/promoters`,paging);

    const getAllCities = () => api.get(`City`);

    const getAllSupervisors = (paging) => api.get(`Supervisor/supervisors`,paging);

    const addPromoter = (payload) => api.post(`Promoter/add-promoter`, payload);

    const getPromoter = (id) => api.get(`Promoter/${id}`);
    const editPromoter = ({ payload, id }) =>api.put(`Promoter/edit-promoter/${id}`, payload);
  
    const deletePromoter = (id) => api.delete(`Promoter/delete-promoter/${id}`);

    const getAllPromotersAssignments = (paging) => api.get(`Promoter/promoters-assignments`,paging);

    return {
      Promoters: {
        getAllPromoters,
        addPromoter,
        editPromoter,
        deletePromoter,
        getPromoter,
        getAllCities,
        getAllSupervisors,
        getAllPromotersAssignments,
      },
    };
  }
  